.event-reg-wrapper {
	width: 90%;
	margin-bottom: 20px;
	flex-grow: 1;
	overflow-y: auto;
}

.event-reg-toolbar {
    width: 100%;
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
}

.event-reg-toolbar .toolbutton{
    margin: 0 10px;
}

.event-reg-statistics-grid, .event-reg-table-grid {
    width: 95% !important;
    margin: auto !important;
}

.event-reg-table-wrapper {
    height: 70vh;
}