.item-editable-data-container {
	width: 90%;
	margin-bottom: 20px;
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
}

.item-editable-data-grid {
	width: 100%;
	padding: 20px;
}

.item-image-edit-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
}

.item-edit-draggable-img {
	width: 150px;
}

.item-edit-draggable-img:hover {
	box-sizing: border-box;
	border: 3px dotted #333;
}

.item-edit-draggable-delete {
	z-index: 100;
	position: absolute;
	top: 0;
	right: 0;

	padding: 5px;
	aspect-ratio: 1/1;
	background-color: #df6c08;

	display: flex;
	align-items: center;
	justify-content: center;
}

.item-edit-draggable-delete:hover {
	background-color: #ff0000e1;
}

/* Add Image Button */
.item-edit-image-add {
	border: none;
	width: 150px;
	height: 150px;
	background-color: #d2ccc9;
	border-radius: 8px;
	padding: 20px;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}

.item-edit-image-add:hover {
	background-color: #bfb9b6;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-edit-image-add:active {
	background-color: #9d9794;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-edit-image-add.disabled {
	cursor: not-allowed;
	background-color: #00000014;
}

.item-edit-image-add-text {
	text-align: center;
	font-size: 14px;
	color: #333;
}
