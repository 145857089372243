.dash-sidebar {
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
}

.dash-sidebar .list-item-link.active {
	background-color: #f1f1f1;
}

.dash-sidebar .list-item-link.left-border {
	border-left: 5px solid transparent; 
	padding-left: 35px;
}
.dash-sidebar .list-item-link.left-border.active {
	border-left: 5px solid #1976d2; 
}
