.dash-wrapper {
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
}
.dash-header {
	width: 100%;
	height: 7%;
	min-height: 50px;
	max-height: 60px;

	padding: 0 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	z-index: 10;
}
.dash-body {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	height: 100%;
	overflow: hidden;

	position: relative;
}

.dash-sidebar-wrapper {
	width: 20%;
	height: 100%;
	min-width: 220px;
	max-width: 250px;
    transition: transform 0.3s ease-in-out 0s;
}

.dash-sidebar-overlay {
    display: none;
}

.dash-menu-icon {
	display: none !important;
}

.dash-content {
	flex-grow: 1;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	overflow: auto;
}

@media only screen and (max-width: 1000px) {
	.dash-header {
		padding : 0 5px;
	}

	.dash-menu-icon {
		display: block !important;
		width: 40px;
		height: 40px;
	}

	.dash-sidebar-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 40%;
		z-index: 9;
		transform: translateX(-100%);
	}

    .dash-sidebar-overlay[data-open='true'] {
        display: block;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        z-index: 8;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
    }

	.dash-sidebar-wrapper[data-open='true'] {
		transform: translateX(0);
	}
}
