.event-edit-container {
	width: 90%;
	margin-bottom: 20px;
	flex-grow: 1;
	overflow-y: auto;
	
	position: relative;
}

.event-edit-grid {
	width: 100%;
	padding: 20px;
}

.event-edit-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 1;
}
