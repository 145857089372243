.item-data-container {
	width: 90%;
	margin-bottom: 20px;
	flex-grow: 1;
	overflow-y: auto;
}

.item-data-grid {
	width: 100%;
	padding: 20px;
}

.item-view-media-row {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
}

.item-view-media-container {
	width: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;
}

.item-view-media-image {
	width: 150px;
	aspect-ratio: initial;
	
	user-select: none;
}

.item-view-media-image:hover {
	border: 3px dotted rgb(107, 107, 114);
}

.item-view-media-index {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgb(107, 107, 114);
	color: white;
	width: 30px;
	height: 30px;
	aspect-ratio: 1;
	
	display: flex;
	justify-content: center;
	align-items: center;
}

.item-view-media-image:hover ~ .item-view-media-index {
	display: none;
}
