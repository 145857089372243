.event-desc-toolbar {
    width: 90%;
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
}

.event-desc-toolbar .toolbutton{
    margin: 0 10px;
}