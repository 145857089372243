.event-data-container {
	width: 90%;
	margin-bottom: 20px;
	flex-grow: 1;
	overflow-y: auto;
    
}

.event-data-grid {
	width: 100%;
	padding: 20px;
}
